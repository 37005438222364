<template>
  <div>
    <div class="table-head">
        <div class="head-title">
            <p>每个群只能设置一条欢迎语，多个群可以共用一条欢迎语。</p>
        </div>
    </div>
    <div class="line-height"></div>
    <div class="table-head">
      <div class="table-search">
        <div style="display:flex;align-items:center">
          <el-button  type="primary" @click="addWelcome">添加欢迎语</el-button>
          <el-button  @click="setSendTime">设置发送时间段</el-button>
          <p style="margin-left:10px;font-size: 14px;">每天<span class="color">{{searchOption.start}}:00~{{searchOption.end}}:00</span>发送，其余时间不发送</p>
        </div>
      </div>
    </div>
    <div class="line-height"></div>
    <div class="table-box">
        <el-table :data="tableData" style="width: 100%" border>
            <el-table-column prop="TaskName" label="任务名称"></el-table-column>
            <el-table-column prop="TimeSpan" label="发送间隔（秒）"></el-table-column>
            <el-table-column prop="CreateTime" label="创建时间"></el-table-column>
            <el-table-column prop="IsEnable" label="启用状态">
                <template #default="scope">
                   <div class="cell">
                       <el-switch v-model="scope.row.IsEnable" active-color="#13ce66" :active-value="1" :inactive-value="0" @change="changeSwitch(scope.row)"/>
                   </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="150">
                <template #default="scope">
                    <el-button type="primary" @click="editAction(scope.row)">编辑</el-button>
                    <el-button type="primary" @click="delAction(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <TableFoot :pagination="pageObj" @changePage="changePage" @changeSize="changeSize"/>
        <GroupWelcomeTime ref="groupTimeRef" @onConfirm="sureAction"/>
    </div>
  </div>
</template>
<script>
import { reactive,ref,onMounted} from "vue";
import { useRoute, useRouter } from "vue-router";
import TableFoot from "@/components/TableFoot";
import { ElMessageBox,ElMessage } from 'element-plus';
import {WelcomeMsgGetList,WelcomeMsgDelete,SetWelcomeMsg,ConfigGetWelcomeMsgHour} from '@/helper/group'
import Tool from '@/assets/js/tools'
import { useStore } from "vuex";
import GroupWelcomeTime from "@/components/GroupManager/GroupWelcomeTime";
export default {
  name: "GroupWelcome",
  components: {
    TableFoot,GroupWelcomeTime
  },
  beforeRouteEnter(to, from, next){
        next(vm=>{
            if(from.path == '/createwelcome'){
                to.meta.getStore = true
            }
        })
    },
  beforeRouteLeave(to, from, next){
        this.$store.commit("GroupWelcome/setData",{
            pageObj:this.pageObj,
            searchOption:this.searchOption,
        })
        next()
  },
  setup() {
    const router = useRouter();
    const tableData = ref([]);
    const store = useStore()
    const route = useRoute()
    const searchOption=reactive({
      start:"",
      end:""
    })

    const groupTimeRef=ref(null)
    onMounted(()=>{
      ConfigGetWelcomeMsgHour().then(res=>{
        searchOption.start=res.StartHour
        searchOption.end=res.EndHour
      })
    
      resetStore()
      searchAction()
    })
    function resetStore(){
        if(route.meta.getStore&& store.state.GroupWelcome.pageObj){
            pageObj.TotalRow= store.state.GroupWelcome.pageObj.TotalRow,
            pageObj.PageIndex= store.state.GroupWelcome.pageObj.PageIndex,
            pageObj.PageSize=store.state.GroupWelcome.pageObj.PageSize
        }
    }
    
    function addWelcome() {
        router.push({ name: "CreateWelcome"});
    }
    function editAction(item) {
      router.push({ name: "CreateWelcome",query:{SID:item.ID}});
    }

    function setSendTime(){
      groupTimeRef.value.initCop()
    }

    function sureAction(obj){
      searchOption.start=obj.StartHour
      searchOption.end=obj.EndHour
    }
    /******************************搜索**********************************/
    function searchAction(){
      let param={
        PageNum:pageObj.PageIndex,
        PageSize:pageObj.PageSize,
      }
      WelcomeMsgGetList(param).then(res=>{
        pageObj.TotalRow=res.TotalCount
        tableData.value=res.List
      })
    }


    //切换状态
    function changeSwitch(item) {
      let param={
        TaskID:item.ID,
        Status:item.IsEnable
      }
        SetWelcomeMsg(param).then(res=>{
          searchAction()
        })
    }

    //删除
    function delAction(item) {
       ElMessageBox.confirm("是否确认删除此条欢迎语？", '删除提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          WelcomeMsgDelete({TaskID:item.ID}).then(res=>{
            ElMessage({
              type: 'success',
              message: '删除成功!',
            });
            searchAction()
          })
        }).catch(() => {
            ElMessage({
              type: 'info',
              message: '已取消',
            });
        });
    }
    /****************TableFoot*******************/
    const pageObj = reactive({
      TotalRow: 0,
      PageIndex: 1,
      PageSize: 10,
    });
    //页码改变
    function changePage(index) {
      pageObj.PageIndex = index;
      searchAction();
    }
    function changeSize(size) {
      pageObj.PageSize = size;
      pageObj.PageIndex = 1;
      searchAction();
    }
    return {
        tableData,
        pageObj,
        changePage,
        changeSize,
        searchAction,
        addWelcome,
        setSendTime,
        changeSwitch,
        delAction,
        editAction,
        groupTimeRef,
        sureAction,
        searchOption
    };
  },
};
</script>
<style lang="scss" scoped>
.head-title{
    padding:20px 0;
    margin-left: 20px;
    font-size: 14px;
}
:deep().el-button span{
    margin-right: 0px;
}

.color{
  margin: 0;
  color: $color-common;
}

</style>