<template>
  <div class="container">
    <el-dialog title="设置发送时间段" width='600px' v-model="dialogVisible">
    <div>请设置每天发送欢迎语的时间段，其余时间不发送</div>
    <div class="cell-wrap">
        <div>开始时间：</div>
        <el-select v-model="searchOption.data.StartHour" placeholder="请选择" style="width:100px" >
            <div  v-if="!showPolicy">
                <el-option
                    v-for="item in timeList"
                    :key="item"
                    :label="item"
                    :value="item"
                />
            </div>
            <div v-else>
                <el-option
                    v-for="item in elList"
                    :key="item"
                    :label="item"
                    :value="item"
                />
            </div>
        </el-select>
        <span class="cell">时</span>
        <span class="cell">~</span>
        <div>结束时间：</div>
        <el-select v-model="searchOption.data.EndHour" placeholder="请选择" style="width:100px" >
           <div  v-if="!showPolicy">
                <el-option
                    v-for="item in timeList"
                    :key="item"
                    :label="item"
                    :value="item"
                />
            </div>
            <div v-else>
                <el-option
                    v-for="item in elList"
                    :key="item"
                    :label="item"
                    :value="item"
                />
            </div>
        </el-select>
        <span class="cell">时</span>
    </div>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmAction">确 定</el-button>
            </div>
        </template>
    </el-dialog>
  </div>
</template>


<script>
import { onMounted, reactive, ref, unref } from "vue";
import {ConfigGetWelcomeMsgHour,ConfigSetWelcomeMsgHour} from '@/helper/group'
import { ElMessageBox,ElMessage } from 'element-plus';

export default {
  name: "SwitchDialog",
  setup(props, context) {
    const dialogVisible = ref(false);
    const showPolicy=ref(false)
    const timeList=ref([7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23])
    const elList=ref([10,11,12,13,14,15,16,17,18,19,20,21,22])
    const searchOption=reactive({
        data:{
            StartHour:"",
            EndHour:""
        }
     
    })
    onMounted(()=>{})
    
    function initCop() {
        let host=window.location.host
        if(host=='esteehelperuat.siyugj.com'){
            showPolicy.value=true
        }
        dialogVisible.value = true;
        ConfigGetWelcomeMsgHour().then(res=>{
            searchOption.data.StartHour=parseInt(res.StartHour)
            searchOption.data.EndHour=parseInt(res.EndHour)
        })
    }

   
    /*****************点击事件*********************/

    //确认
    function confirmAction() {
        if(parseInt(searchOption.data.StartHour)>parseInt(searchOption.data.EndHour)){
            ElMessage({
                type: "warning",
                message: "开始时间不能大于结束时间",
            });
            return
        }
        let param={
            StartHour:searchOption.data.StartHour,
            EndHour:searchOption.data.EndHour
        }
        ConfigSetWelcomeMsgHour(param).then(res=>{
            dialogVisible.value = false;
            context.emit("onConfirm",searchOption.data);
        })
    }
    return {
      initCop,
      dialogVisible,
      confirmAction,
      timeList,
      searchOption,
      showPolicy,
      elList
    };
  }
};
</script>
<style lang='scss' scoped>
.wrap{
    margin: 30px 20px;
    display: flex;
    align-items: center;
}
.cell-wrap{
    display: flex;
    align-items: center;
    margin:10px 0;
}
.cell{
    margin: 0 10px;
}
</style>